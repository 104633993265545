import React from "react"
import { Link } from "gatsby"
import Layout from "../Components/Layout"
import Box from "../Components/Box"
import TopIlliustration from "../assets/images/about-illiustration.jpg"

export default () => (
  <Layout>
    <div className="about-page">
      <Box>
        <h2>Dažniausiai užduodami klausimai</h2>

        <div className="tabs">
          <div className="tab">
            <input type="checkbox" id="chck1" />
              <label className="tab-label" htmlFor="chck1">Kas gali dalyvauti egzamine?</label>
              <div className="tab-content">
                <p>
                  Egzaminas yra atviras ir nemokamas visiems norintiems dalyvauti. Egzamino klausimai yra suskirstyti į keturias skirtingas kategorijas:
                </p>
                <p>
                  „Pradedantysis” – rekomenduojama 7–9 kl. moksleiviams (jaunesni nei 14 metų asmenys turi atsiųsti laisvos formos tėvų sutikimą adresu <a href="mailto:info@zaliojipolitika.lt" title="info@zaliojipolitika.lt">
                  info@zaliojipolitika.lt
                </a>); <br/>
                  „Smalsuolis” – rekomenduojama 10–12 kl. moksleiviams;<br/>
                  „Tyrinėtojas” – plačiajai visuomenei;<br/>
                  „Ekspertas” – aplinkosaugos specialistams ar intensyviai besidomintiems.
                </p>
                <p>
                  Dalyviai registracijos metu pasirenka jų patirtį atitinkančią kategoriją.
                </p>

              </div>
          </div>
          <div className="tab">
            <input type="checkbox" id="chck2"/>
              <label className="tab-label" htmlFor="chck2">Kada vyks egzaminas?</label>
              <div className="tab-content">
                <p>
                  Egzaminas vyks spalio 24 d. – Tarptautinę klimato kaitos dieną.<br/>
                  Egzamino laikas: 8.00–20.00 val. Egzaminą galima laikyti dalyviui patogiu metu.<br/>
                </p>
              </div>
          </div>

          <div className="tab">
            <input type="checkbox" id="chck3"/>
            <label className="tab-label" htmlFor="chck3">Kaip užsiregistruoti laikyti egzaminą?</label>
            <div className="tab-content">
              <p>
                <u>Iki egzamino dienos (spalio 24 d.):</u> <br/>
                Norėdami laikyti egzaminą, dalyviai turi užsiregistruoti. Registracijos forma pasiekiama visą laiką iki egzamino dienos pabaigos.
                Registracijos metu dalyviai pasirenka jų patirtį atitinkančią kategoriją, atidžiai užpildo registracijos formą. Svarbu patikrinti, ar teisingai įvestas el. pašto adresas, vardas ir pavardė. Ši informacija itin svarbi, kad galėtume sėkmingai susisiekti su dalyviais ir pasidalinti egzamino rezultatais bei pakviesti egzamino laureatus į apdovanojimų šventę.
              </p>
              <p>
                <u>Egzamino dieną (spalio 24 d.):</u><br/>
                Dalyviai prisijungia prie savo egzamino paskyros naudodamiesi registracijoje pateiktu el. pašto adresu. Priminimas apie renginį dalyviams bus išsiųstas egzamino dieną el. paštu.
              </p>
            </div>
          </div>

          <div className="tab">
            <input type="checkbox" id="chck4"/>
            <label className="tab-label" htmlFor="chck4">Kokia yra egzamino eiga?</label>
            <div className="tab-content">
              <p>
                Egzaminas sudarytas iš testinių klausimų skirtingomis aplinkosaugos temomis (klimatas, atliekos, gyvoji gamta, politika, energetika ir t.t.). Kiekvienas klausimas turi po vieną teisingą atsakymą. Pasirinkus variantą ir paspaudus mygtuką „tęsti“ dalyvis pereis prie kito egzamino klausimo. <br/>
              </p>
            </div>
          </div>

          <div className="tab">
            <input type="checkbox" id="chck5"/>
            <label className="tab-label" htmlFor="chck5">Kaip išsirinkti dalyvių kategoriją?</label>
            <div className="tab-content">
              <p>
                Egzamino dalyviai yra suskirstyti į keturias skirtingas kategorijas. Kategorija <b>„Pradedantysis“</b> skirta 7–9 kl. moksleiviams, <b>„Smalsuolis“</b> – 10–12 kl. moksleiviams, <b>„Tyrinėtojas“</b> – plačiajai visuomenei, <b>„Ekspertas“</b> – aplinkosauginį išsilavinimą turintiems, šioje srityje dirbantiems arba aktyviai šia tema besidomintiems egzamino dalyviams.
              </p>
              <p>
                Registracijos į egzaminą metu dalyvis pasirenka jo patirtį atitinkančią dalyvių kategoriją. Dalyviai pasirinkę savo amžiaus ar išsilavinimo neatitinkančią kategoriją praranda galimybę dalyvauti egzamino laureatų apdovanojimuose.
              </p>
            </div>
          </div>

          <div className="tab">
            <input type="checkbox" id="chck6"/>
            <label className="tab-label" htmlFor="chck6">Kodėl verta dalyvauti?</label>
            <div className="tab-content">
              <p>
                Nacionalinis aplinkosaugos egzaminas skatina ne tik patikrinti savo aplinkosaugos žinias, bet ir laimėti puikius prizus! <br/>
                Egzamino laureatai bus apdovanoti specialioje apdovanojimų šventėje. Su geriausiai egzaminą išlaikiusiais dalyviais bus asmeniškai susisiekta el. paštu.
              </p>
            </div>
          </div>

          <div className="tab">
            <input type="checkbox" id="chck7"/>
            <label className="tab-label" htmlFor="chck7">Ką daryti, jei nepavyksta užsiregistruoti?</label>
            <div className="tab-content">
              <p>
                Registracijos ar egzamino metu iškilus techniniams nesklandumams prašome kreiptis {" "}
                <a href="mailto:info@zaliojipolitika.lt" title="info@zaliojipolitika.lt">
                  info@zaliojipolitika.lt
                </a>
              </p>
            </div>
          </div>
        </div>


        {/*<div className="text-container">*/}
        {/*  <h2>X</h2>*/}
        {/*  <p>*/}

        {/*  </p>*/}
        {/*</div>*/}


      </Box>
    </div>
  </Layout>
)
